import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import detector from "i18next-browser-languagedetector";

// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init
const en_us = require('src/i18n/en-US/translation.json');
const zh_hant = require('src/i18n/zh-hant/translation.json');
const zh_hans = require('src/i18n/zh-hans/translation.json');
const resources = {
  en: {
    translation: en_us,
  },
  zht: {
    translation: zh_hant
  },
  zhs: {
    translation: zh_hans
  },
}
i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',

    saveMissing: true, // send not translated keys to endpoint
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  })


export default i18n;