import "./App.css";
import { ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { createTheme } from "src/theme";
import MomentUtils from "@date-io/moment";
import { SnackbarProvider } from "notistack";
import routes, { renderRoutes } from "src/routes";
import { AuthProvider } from "src/contexts/JWTAuthContext";
import { ConfirmProvider } from "material-ui-confirm";
import { THEMES } from "src/constants";

const history = createBrowserHistory();

function App() {
  const theme = createTheme({
    direction: "ltr",
    responsiveFontSizes: true,
    theme: THEMES.LIGHT,
  });

  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider dense maxSnack={3}>
            <Router history={history}>
              <AuthProvider>{renderRoutes(routes)}</AuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </ConfirmProvider>
    </ThemeProvider>
  );
}

export default App;
