import { createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";

const initialState = {
  organizations: [],
  selectedOrganization: null,
  isLoading: true,
};

const slice = createSlice({
  name: "Organization",
  initialState,
  reducers: {
    getOrganization(state, action) {
      const { organizations, selectedOrganization, isLoading } = action.payload;
      let orgs = [...organizations];
      orgs?.sort((a, b) => {
        if (a?.name > b?.name) {
          return 1;
        } else if (a?.name < b?.name) {
          return -1;
        } else {
          return 0;
        }
      });
      state.organizations = orgs;
      state.selectedOrganization = selectedOrganization;
      state.isLoading = isLoading;
    },
  },
});

export const reducer = slice.reducer;

export const getOrganization = () => async (dispatch) => {
  try {
    const response = await axios.get(`/org?showAll=true`);
    // keep updating accessToken to make sure it's always update to date for api calls
    const selectedOrgId = localStorage.getItem("selectedOrgId");
    const activeOrg = () => {
      if (selectedOrgId) {
        return response?.data?.filter((org) => org?.id === selectedOrgId)[0];
      } else {
        return response?.data[0];
      }
    };
    if (response) {
      dispatch(
        slice.actions.getOrganization({
          organizations: response.data,
          selectedOrganization: activeOrg(),
          isLoading: false,
        })
      );
    }
  } catch (err) {
    localStorage.clear();
    window.location.href = "/login";
  }
};

export const selectOrganization = (organizations, userSelectedOrg) => async (
  dispatch
) => {
  if (userSelectedOrg) {
    localStorage.setItem("selectedOrgId", userSelectedOrg?.id);
  }
  dispatch(
    slice.actions.getOrganization({
      organizations: organizations,
      selectedOrganization: userSelectedOrg,
      isLoading: false,
    })
  );
};

export const resetOrganizations = () => async (dispatch) => {
  dispatch(
    slice.actions.getOrganization({
      organizations: [],
      selectedOrganization: null,
      isLoading: true,
    })
  );
};

export default slice;
