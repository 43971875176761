export const APP_VERSION = "0.1.0";

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: "LIGHT",
  ONE_DARK: "ONE_DARK",
  UNICORN: "UNICORN",
};

export const LANGUAGES = [
  {
    id: "en",
    value: "English",
  },
  {
    id: "zht",
    value: "繁體中文",
  },
  {
    id: "zhs",
    value: "简体中文",
  },
];
