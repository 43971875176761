import { createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";

const initialState = {
  account: null,
  isLoading: true,
};

const slice = createSlice({
  name: "Account",
  initialState,
  reducers: {
    getAccount(state, action) {
      const { account, isLoading } = action.payload;
      state.account = account;
      state.isLoading = isLoading;
    },
  },
});

export const reducer = slice.reducer;

export const getAccount = () => async (dispatch) => {
  try {
    const response = await axios.get(`/api/account`);
    if (response) {
      dispatch(
        slice.actions.getAccount({
          account: response.data.data,
          isLoading: false,
        })
      );
    }
  } catch (err) {
    // localStorage.clear();
    // window.location.href = "/login";
  }
};

export default slice;
