import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { AppBar, Box, makeStyles, Toolbar } from "@material-ui/core";

import Logo from "src/components/Logo";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
  },
  toolbar: {
    height: 64,
  },
  logo: {
    marginRight: theme.spacing(2),
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const TopBar = ({ className, setLanguage, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <Box p={2} display="flex" justifyContent="center">
          <Logo
            onClick={() => {
              window.location.href = `${process.env.REACT_APP_LANDING_URL}`;
            }}
          />
        </Box>
        <Box flexGrow={1} />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};

export default TopBar;
