import axios from "axios";

export const API_GATEWAY_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const axiosInstance = axios.create({
  baseURL: API_GATEWAY_ENDPOINT,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
  proxy: false,
  withCredentials: true,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || Promise.reject(error)
    )
);

export default axiosInstance;
