import { createSlice } from "@reduxjs/toolkit";
import axios from "src/utils/axios";

const initialState = {
  total: 0,
  available: 0,
  used: 0,
  expired_at: null,
  plan: null,
};

const slice = createSlice({
  name: "Quota",
  initialState,
  reducers: {
    getQuota(state, action) {
      const { quota, isLoading } = action.payload;
      state.quota = quota;
      state.isLoading = isLoading;
    },
  },
});

export const reducer = slice.reducer;

export const getQuota = () => async (dispatch) => {
  try {
    const response = await axios.get(`/api/account`);
    if (response) {
      const { quota } = response.data.data;

      if (quota) {
        dispatch(
          slice.actions.getQuota({
            quota,
            isLoading: false,
          })
        );
      }
    }
  } catch (err) {
    // localStorage.clear();
    // window.location.href = "/login";
  }
};

export default slice;
