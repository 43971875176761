import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Avatar,
  Box,
  ButtonBase,
  Menu,
  Tooltip,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import useAuth from "src/hooks/useAuth";
import { useTranslation } from "react-i18next";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 200,
  },
  listItemText: {
    fontSize: "1em",
  },
  item: {
    padding: 0,
  },
  avater: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
}));

const Account = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const ref = useRef(null);
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      history.push("/");
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t("message.technicalDifficulty"), {
        variant: "error",
      });
    }
  };

  return (
    <>
      <List disablePadding>
        <ListItem onClick={handleOpen} classes={{ root: classes.item }}>
          <Box
            display="flex"
            style={{ textAlign: "left" }}
            component={ButtonBase}
            ref={ref}
          >
            <ListItemAvatar>
              <Tooltip title={`${user?.email}`}>
                <Avatar className={classes.avater}>
                  {user?.first_name[0].toUpperCase()}
                </Avatar>
              </Tooltip>
            </ListItemAvatar>
            <ListItemText
              classes={{
                primary: classes.listItemText,
                secondary: classes.listItemText,
              }}
              primary={
                <strong>{user?.first_name + " " + user?.last_name}</strong>
              }
              secondary={user?.email}
            />
          </Box>
        </ListItem>
      </List>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem onClick={handleLogout}>{t("common.logout")}</MenuItem>
      </Menu>
    </>
  );
};

export default Account;
