import React, { useCallback, useEffect, useState } from "react";
import { useLocation, matchPath } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  LinearProgress,
} from "@material-ui/core";
import {
  PieChart as PieChartIcon,
  CreditCard as CreditCardIcon,
  FileText,
  User,
  Key,
} from "react-feather";
import Logo from "src/components/Logo";
import NavItem from "./NavItem";
import { useDispatch, useSelector } from "src/store";
import { getAccount } from "src/slices/account";
import { useTranslation } from "react-i18next";
import CodeIcon from "@material-ui/icons/Code";

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
}));

const NavBar = ({ onMobileClose, openMobile, language }) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const { account, isLoading: isLoadingAccount } = useSelector(
    (state) => state.account
  );
  let [sections, setSections] = useState([]);
  const { t } = useTranslation();

  const operatorSections = useCallback(() => {
    return [
      {
        subheader: "",
        items: [
          {
            title: t("nav.dashboard"),
            icon: PieChartIcon,
            href: "/app/dashboard",
          },
          {
            title: t("nav.orders"),
            href: "/app/orders",
            icon: FileText,
          },
          {
            title: t("nav.apiKeys"),
            icon: Key,
            href: "/app/api-keys",
          },
          {
            title: t("nav.apiDoc"),
            icon: CodeIcon,
            href: "/app/api-doc",
          },
          {
            title: t("nav.account"),
            href: "/app/account",
            icon: User,
          },
          {
            title: t("nav.billing"),
            href: "/app/billing",
            icon: CreditCardIcon,
          },
        ],
      },
    ];
  }, [t]);

  const handleSetSection = useCallback(() => {
    setSections(operatorSections);
  }, [operatorSections]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    if (!isLoadingAccount) {
      handleSetSection();
    } else {
      dispatch(getAccount());
    }
  }, [
    location.pathname,
    isLoadingAccount,
    dispatch,
    openMobile,
    onMobileClose,
    handleSetSection,
  ]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Divider />
        <Box p={2}>
          {account &&
            sections.map((section) => (
              <List
                key={section.subheader}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                })}
              </List>
            ))}
          {!account && <LinearProgress />}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
