import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import useAuth from "../hooks/useAuth";
import { useSelector } from "src/store";

const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const {
    selectedOrganization,
    isLoading: isLoadingOrganization,
  } = useSelector((state) => state.organizations);
  const url = new URL(window.location.href);
  const is403 = url?.pathname?.split("/")?.includes("403");

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  if (
    !is403 &&
    !isLoadingOrganization &&
    selectedOrganization?.isApproved === "2"
  ) {
    return <Redirect to="/app/403" />;
  } else if (
    is403 &&
    !isLoadingOrganization &&
    selectedOrganization?.isApproved === "1"
  ) {
    return <Redirect to="/app" />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
