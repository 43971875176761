import React, { Suspense, Fragment, lazy } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import MainLayout from "src/layouts/MainLayout";
import LoadingScreen from "src/components/LoadingScreen";
import AuthGuard from "src/components/AuthGuard";
import GuestGuard from "src/components/GuestGuard";

export const renderRoutes = (routes) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    // exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFoundView")),
  },
  {
    exact: true,
    guard: GuestGuard,
    layout: MainLayout,
    path: "/login",
    component: lazy(() => import("src/views/auth/LoginView")),
  },
  {
    exact: true,
    guard: GuestGuard,
    layout: MainLayout,
    path: "/register",
    component: lazy(() => import("src/views/auth/RegisterView")),
  },
  {
    exact: true,
    layout: MainLayout,
    path: "/forgot-password",
    component: lazy(() => import("src/views/auth/ForgotPasswordView")),
  },
  {
    exact: true,
    path: "/logout",
    component: lazy(() => import("src/views/auth/LogoutView")),
  },
  {
    path: "/app",
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: "/app/dashboard",
        component: lazy(() => import("src/views/dashboard")),
      },
      {
        exact: true,
        path: "/app/orders",
        component: lazy(() => import("src/views/orders")),
      },
      {
        exact: true,
        path: "/app/api-keys",
        component: lazy(() => import("src/views/api-keys")),
      },
      {
        exact: true,
        path: "/app/account",
        component: lazy(() => import("src/views/account")),
      },
      {
        exact: true,
        path: "/app/billing/:plan?",
        component: lazy(() => import("src/views/billing")),
      },
      {
        exact: true,
        path: "/app/403",
        component: lazy(() => import("src/views/errors/NotPermittedView")),
      },
      {
        exact: true,
        path: "/app",
        component: () => <Redirect to="/app/dashboard" />,
      },
      {
        exact: true,
        path: "/app/api-doc",
        component: lazy(() => import("src/views/api-doc")),
      },
      // {
      //   component: () => <Redirect to="/404" />,
      // },
    ],
  },
  {
    path: "*",
    routes: [
      {
        // exact: true,
        path: "/",
        component: () => <Redirect from="/" to="/login" />,
      },
      // {
      //   exact: true,
      //   path: "/terms",
      //   component: lazy(() => import("src/views/static/Terms")),
      // },
      // {
      //   component: () => <Redirect to="/404" />,
      // },
    ],
  },
];

export default routes;
