import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import TopBar from "./TopBar";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 64,
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
  },
  content: {
    flex: "1 1 auto",
    height: "100%",
    overflow: "auto",
  },
}));

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const MainLayout = ({ children }) => {
  const classes = useStyles();
  const { i18n } = useTranslation();
  const savedLanguage = localStorage.getItem("language");
  const query = useQuery();
  const queryParamLanguage = query.get("language");
  const [language, setLanguage] = useState(
    queryParamLanguage ? queryParamLanguage : savedLanguage
  );

  const handleLanguageChange = useCallback(
    async (value) => {
      localStorage.setItem("language", value);
      setLanguage(value);
      i18n.changeLanguage(value);
    },
    [i18n]
  );
  useEffect(() => {
    handleLanguageChange(language);
  }, [language, handleLanguageChange]);

  return (
    <div className={classes.root}>
      <TopBar setLanguage={handleLanguageChange} />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
