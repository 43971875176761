import { combineReducers } from "@reduxjs/toolkit";
import { reducer as formReducer } from "redux-form";
import { reducer as organizationReducer } from "src/slices/organization";
import { reducer as accountReducer } from "src/slices/account";
import { reducer as quotaReducer } from "src/slices/quotaSlice";

const rootReducer = combineReducers({
  form: formReducer,
  organizations: organizationReducer,
  account: accountReducer,
  quota: quotaReducer,
});

export default rootReducer;
